import React, { useState, useContext, useEffect } from "react"

import { IBooking, IPresent, IArticle, ISimpleCoupon, IBilling, IBookingInfos } from "src/types"
import { formatMoney } from "src/helpers/text"
import { MAIL_PRICE } from "src/constants"

const CART_VERSION = 3

interface ICartContext {
  bookings?: IBooking[]
  presents?: IPresent[]
  empty: boolean
  add: (article: IArticle, delta?: number) => void
  coupon?: ISimpleCoupon
  setCoupon: (coupon: ISimpleCoupon) => void
  billing?: IBilling
  setBilling: (billing: IBilling) => void
  setInfos: (infos: IBookingInfos[][]) => void
  getDiscount: () => string
  getNumArticles: () => number
  getTotal: () => number
  reset: () => void
}

const CartContext = React.createContext<ICartContext>(null)

export const getKey = (article: IArticle, index?: number) => {
  const attr: string[] = [article.type, article.formula.id]
  if (article.type === "booking") {
    attr.push(article.date.id)
    if (article.variant) {
      attr.push(article.variant)
    }
  } else {
    attr.push(String(article.mail))
  }
  if (index) {
    attr.push(String(index))
  }
  return attr.join(".")
}

const bookingReducer = (prev: number, curr: IBooking) => prev + curr.formula.price * curr.quantity

const presentReducer = (prev: number, curr: IPresent) =>
  prev + (curr.formula.price + (curr.mail ? MAIL_PRICE : 0)) * curr.quantity

const quantityReducer = (prev: number, curr: IArticle) => prev + curr.quantity

export const CartProvider: React.FC = ({ children }) => {
  const [bookings, setBookings] = useState<IBooking[]>()
  const [presents, setPresents] = useState<IPresent[]>()
  const [coupon, setCoupon] = useState<ISimpleCoupon>()
  const [billing, setBilling] = useState<IBilling>()

  const add = (article: IArticle, delta?: number) => {
    const id = getKey(article)
    const list: IArticle[] = (article.type === "booking" ? bookings : presents) || []
    const existing = list.find((item) => getKey(item) === id)
    if (existing) {
      existing.quantity += delta || article.quantity
      if (article.type === "booking") {
        const maxQuantity = Math.floor(article.date.spots / article.formula.people)
        if (existing.quantity > maxQuantity) {
          existing.quantity = maxQuantity
        }
      }
    } else {
      list.push(article)
    }
    const setArticles: React.Dispatch<React.SetStateAction<IArticle[]>> =
      article.type === "booking" ? setBookings : setPresents
    setArticles(list.filter(({ quantity }) => quantity > 0))
  }

  const setInfos = (infos: IBookingInfos[][]) => {
    if (infos) {
      setBookings(
        bookings.map((booking, index) => {
          booking.infos = infos[index]
          return booking
        })
      )
    }
  }

  const getTotal = () => {
    const bookingsTotal = bookings ? bookings.reduce(bookingReducer, 0) : 0
    const presentsTotal = presents ? presents.reduce(presentReducer, 0) : 0
    const sum = bookingsTotal + presentsTotal

    if (coupon) {
      if (coupon.type === "money") {
        return Math.max(0, sum - coupon.value)
      } else if (coupon.type === "percent") {
        return sum - (sum * coupon.value) / 100
      }
    }
    return sum
  }

  const getNumArticles = () => {
    const numBookings = bookings ? bookings.reduce(quantityReducer, 0) : 0
    const numPresents = presents ? presents.reduce(quantityReducer, 0) : 0
    return numBookings + numPresents
  }

  const getDiscount = () => {
    if (coupon) {
      if (coupon.type === "money") {
        return `- ${formatMoney(coupon.value)}`
      } else if (coupon.type === "percent") {
        return `- ${coupon.value} %`
      }
    }
    return ""
  }

  const reset = () => {
    setBookings([])
    setPresents([])
    setCoupon(null)
    setBilling(null)
  }

  useEffect(() => {
    try {
      const stored = localStorage.getItem("cart")
      const data = JSON.parse(stored)
      if (data && data.version === CART_VERSION) {
        // TODO: check integrity
        if (data.bookings) {
          setBookings(data.bookings)
        }
        if (data.presents) {
          setPresents(data.presents)
        }
        if (data.coupon) {
          setCoupon(data.coupon)
        }
        if (data.billing) {
          setBilling(data.billing)
        }
      }
    } catch (error) {
      // ignore
    }
  }, [])

  useEffect(() => {
    if (!bookings && !presents) {
      // not loaded yet => don't empty cart
      return
    }
    try {
      localStorage.setItem("cart", JSON.stringify({ bookings, presents, coupon, billing, version: CART_VERSION }))
    } catch (error) {
      // ignore
    }
  }, [bookings, presents, coupon, billing])

  const empty = (!bookings || !bookings.length) && (!presents || !presents.length)

  return (
    <CartContext.Provider
      value={{
        bookings,
        presents,
        empty,
        coupon,
        billing,
        add,
        setCoupon,
        setBilling,
        setInfos,
        getDiscount,
        getNumArticles,
        getTotal,
        reset,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export const useCart = () => useContext(CartContext)
