export const COLORS = {
  main: "#E10F14",
  dark: "#1A1919",
  light: "white",
  placeholder: "#ccc",
}

export const SIZES = {
  small: "1em",
  normal: "1.2em",
  medium: "1.5em",
  desc: "1.75em",
  large: "2em",
  big: "2.5em",
  huge: "3.5em",
}

export const BREAKPOINTS = {
  maxWidth: 1100, // including 2x60 px of padding
  maxTextWidth: 830,
  tablet: 900,
  mobile: 500,
}

export const EMAIL_ADDRESS = "contact@atelierschuteslibres.com"

export const INSTAGRAM = "atelierschuteslibres"
export const FACEBOOK = "atelierschuteslibres"
export const LINKEDIN_URL = "https://www.linkedin.com/company/ateliers-chutes-libres2/"
export const PINTEREST = "atelierschuteslibres"

export const GOOGLE_MAPS = "https://goo.gl/maps/XpD4w9aadaKbCsmL6"
export const MAIL_PRICE = 5
