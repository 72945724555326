import { css } from "styled-components"

import { COLORS, BREAKPOINTS } from "src/constants"

export default css`
  body {
    font-family: "Courier New", Courier, monospace;
    overflow-x: hidden;
    color: ${COLORS.dark};
    font-size: 100%;
    @media (max-width: ${BREAKPOINTS.mobile}px) {
      font-size: 80%;
    }
  }
  a {
    text-decoration: none;
    color: ${COLORS.dark};
  }
  button {
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
    padding: 1px 7px 2px;
  }
  input,
  textarea {
    outline: none;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  sup {
    line-height: 0.5em;
  }
  .gatsby-image-wrapper > img {
    image-rendering: pixelated;
  }
  figure {
    margin: 0;
  }
`
