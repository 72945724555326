// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cgv-tsx": () => import("./../src/pages/cgv.tsx" /* webpackChunkName: "component---src-pages-cgv-tsx" */),
  "component---src-pages-commande-tsx": () => import("./../src/pages/commande.tsx" /* webpackChunkName: "component---src-pages-commande-tsx" */),
  "component---src-pages-confirmation-tsx": () => import("./../src/pages/confirmation.tsx" /* webpackChunkName: "component---src-pages-confirmation-tsx" */),
  "component---src-pages-equipe-tsx": () => import("./../src/pages/equipe.tsx" /* webpackChunkName: "component---src-pages-equipe-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-groupes-entreprises-tsx": () => import("./../src/pages/groupes-entreprises.tsx" /* webpackChunkName: "component---src-pages-groupes-entreprises-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-infos-tsx": () => import("./../src/pages/infos.tsx" /* webpackChunkName: "component---src-pages-infos-tsx" */),
  "component---src-pages-offrir-tsx": () => import("./../src/pages/offrir.tsx" /* webpackChunkName: "component---src-pages-offrir-tsx" */),
  "component---src-pages-paiement-tsx": () => import("./../src/pages/paiement.tsx" /* webpackChunkName: "component---src-pages-paiement-tsx" */),
  "component---src-pages-panier-tsx": () => import("./../src/pages/panier.tsx" /* webpackChunkName: "component---src-pages-panier-tsx" */),
  "component---src-pages-reserver-tsx": () => import("./../src/pages/reserver.tsx" /* webpackChunkName: "component---src-pages-reserver-tsx" */),
  "component---src-pages-tutoriels-tsx": () => import("./../src/pages/tutoriels.tsx" /* webpackChunkName: "component---src-pages-tutoriels-tsx" */),
  "component---src-pages-valeurs-tsx": () => import("./../src/pages/valeurs.tsx" /* webpackChunkName: "component---src-pages-valeurs-tsx" */),
  "component---src-templates-workshop-tsx": () => import("./../src/templates/workshop.tsx" /* webpackChunkName: "component---src-templates-workshop-tsx" */)
}

