import React from "react"
import styled, { createGlobalStyle } from "styled-components"

import normalize from "./normalize"
import style from "./style"
import { ErrorBoundary } from "src/helpers/bugsnag"
import { COLORS, EMAIL_ADDRESS, BREAKPOINTS, SIZES } from "src/constants"
import { CartProvider } from "src/components/cart"

const GlobalStyles = createGlobalStyle`
  ${normalize}
  ${style}
`

const ErrorContainer = styled.div`
  padding: 40px;
  @media (max-width: ${BREAKPOINTS.mobile}px) {
    padding: 40px 20px;
  }
  h1 {
    color: ${COLORS.main};
    font-size: ${SIZES.medium};
  }
  h2 {
    color: ${COLORS.dark};
    font-size: ${SIZES.normal};
  }
  p {
    margin-top: 40px;
  }
`

// https://github.com/bugsnag/bugsnag-js/blob/next/packages/plugin-react/src/index.js#L9-L12
interface FallbackProps {
  error: Error
  info?: {
    componentStack: string
  }
}

const ErrorComponent: React.FC<FallbackProps> = () => (
  <ErrorContainer>
    <h1>
      <span aria-hidden>🐞</span>
      <br />
      Une erreur est survenue.
    </h1>
    <h2>Essayez de recharger la page.</h2>
    <p>
      Si le problème persiste, contactez-nous : <a href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</a>
    </p>
  </ErrorContainer>
)

const Container = styled.div`
  background-color: ${COLORS.light};
`

const Root: React.FC = ({ children }) => (
  <Container>
    <GlobalStyles />
    <ErrorBoundary FallbackComponent={ErrorComponent}>
      <CartProvider>{children}</CartProvider>
    </ErrorBoundary>
  </Container>
)

export default Root
