declare global {
  interface Window {
    jump: any
  }
}

const onClientEntry = (): Promise<any> => {
  const polyfills: Promise<any>[] = []

  if (typeof IntersectionObserver === "undefined") {
    // IE will have to wait for this polyfill to be loaded first, because it's needed for gatsby-image
    polyfills.push(import("intersection-observer"))
  }
  if (typeof fetch === "undefined") {
    polyfills.push(import("whatwg-fetch"))
  }
  // https://stackoverflow.com/a/55221484/1052033
  if (!("scrollBehavior" in document.documentElement.style)) {
    polyfills.push(
      import("jump.js").then((module) => {
        window.jump = module.default
      })
    )
  }
  return Promise.all(polyfills)
}

export default onClientEntry
